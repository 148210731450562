import { api } from '@/adapter';

const getGeoLocation = async (payload: any): Promise <any>  => {
  // TODO implement caching
  return api({
    url: "postcodeLookup", 
    method: "get",
    params: payload,
  });
}

export const UtilityService = {
  getGeoLocation
}